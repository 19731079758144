.cta-section {
  background: $primary-color;
  padding-bottom: 50px;
  position: relative;

  &.page {
    padding-top: 220px;
    &:before {
      content: " ";
      background-image: url(/assets/img/cta_top.png);
      background-size: cover;
      height: 170px;
      width: 100%;
      background-color: #FFF;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  form {
    background: #FFF;
    padding: 20px 50px;
    box-shadow: 10px 10px 30px -10px rgba(0,0,0,0.5);
    border-radius: 5px;
    border: 4px solid #e8e8e8;

    .upsells {
      font-size: 1.2em;
      svg {
        color: $primary-color;
      }
    }
    label {
      font-weight: bolder;
      font-size: 1em;

      & > span {
        color: red;
        opacity: .7;
        &:before {
          content: " ";
        }
      }
    }
    input, select, textarea {
      background: transparentize($primary-color, .8);
      box-shadow: none;
      border: 2px solid $primary-color;
      &:active, &:focus {
        background: #FFF;
        box-shadow: none;
        border: 2px solid $primary-color;
      }
    }

    select {
      margin-bottom: 0;
    }

    .button {
      width:100%;
      font-weight: bolder;
      font-size: 1.1em;
    }
  }
}

.contact-form {
  background: #FFF;


  .upsells {
    font-size: 1.2em;
    svg {
      color: $primary-color;
    }
  }
  label {
    font-weight: bolder;
    font-size: 1em;
  }
  input, select, textarea {
    background: transparentize($primary-color, .8);
    box-shadow: none;
    border: 2px solid $primary-color;
    &:active, &:focus {
      background: #FFF;
      box-shadow: none;
      border: 2px solid $primary-color;
    }
  }

  select {
    margin-bottom: 0;
  }

  .button {
    width:100%;
    font-weight: bolder;
    font-size: 1.1em;
  }
}

#typeform-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}