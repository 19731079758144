.upper-nav {
  position: fixed;
  top: 0;
  background: $primary-color;
  width: 100%;
  z-index: 99;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    float: right;
    padding-right: 50px;
    li {
      display: inline-block;
      margin-right: 10px;
      a {
        line-height: $upper-nav-height;
        color: #FFF;
        font-size: .9em;
        font-weight: bolder;
        svg {
          margin-right: 5px;
          font-size: 1em;
        }
      }
    }
  }
}

.lower-nav {
  position: fixed;
  top:$upper-nav-height;
  right: 0;
  width: 100%;
  z-index: 99;
  background: #FFF;
  height: $lower-nav-height;
  border-bottom: 2px solid #e8e8e8;

  .nav-icon {
    margin: 25px;
    width: 60px;
    float: right;
    z-index: 999;
    background: $primary-color;
    padding: 5px 10px;
    position: relative;
    display: none;
  }

  .logo {
    margin-top: 15px;
    height: 80px;
  }
  
  .nav-icon:after, 
  .nav-icon:before, 
  .nav-icon div {
    background-color: #FFF;
    border-radius: 3px;
    content: '';
    display: block;
    height: 5px;
    margin: 7px 0;
    transition: all .2s ease-in-out;
  }

 

  ul {
    font-family: $body-font-family;
    font-weight: bolder;
    font-size: 1.1em;
    list-style: none;
    padding: 30px 50px 30px 0;
    margin: 0;
    float: right;

    li {
      display: inline-block;
      a {
        padding: 0 12px;
        line-height: 50px;
        color: #222;
        transition: .5s color ease;
        display: inline-block;

        &:hover {
          color: $primary-color;
        }
      }

      &.cta {
        a {
          background: $primary-color;
          padding: 0 50px;
          border-radius: 50px;
          color: #FFF;
        }
      }
    }
  }
}

footer {
  padding-top: 120px;
  position: relative;
  display: block;

  &.no_cta {
    &:before {
      display: none;
    }
  }

  &:before {
    content: " ";
    background-color:  $primary-color;
    background-image: url(/assets/img/cta.jpg);
    background-size: cover;
    background-position: right top;
    height: 150px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .logo {
    filter: grayscale(100);
    width: 250px;
  }

  .quicklinks {
    padding-top: 60px;
    padding-bottom: 50px;
    h3 {
      font-family: $body-font-family;
      font-weight: bolder;
      font-size: 1.3em;
    }

    a {
      display: block;
      line-height: 2em;
    }

    .contact-info {
      line-height: 1.6em;
    }
  }

  .lower {
    background: $navy;
    padding: 20px 0;
    text-align: center;
     p {
       margin: 0;
       padding: 0;
       color: #FFF;
     }
  }
}

@include breakpoint (large down) {
  .lower-nav {
    position: fixed;
    pointer-events: none;

    .nav-icon {
      pointer-events: all;
      display: block;
    }

    ul {
      padding: 0;
      background: #FFF;
      margin: 0;
      width: 100vw;
      transform: translateX(100vw);
      transition: .5s all ease-out;
      z-index: 1;
      li {
        width: 100%;
        padding: 10px 0;
        border-bottom: 2px solid darken(#FFF, 10%);
        a {
          width: 100%;
          text-align: center;
        }

        &.cta {
          padding: 0;
          border-bottom: none;
          a {
            border-radius: 0;
            background: $primary-color;
            padding: 10px 0;
          }
        }
      }
    }

    &.open {
      ul {
        transform: translateX(0);
        pointer-events: all;
      }

      .nav-icon:before {
        transform: translateY(12px) rotate(135deg);
      }
      
      .nav-icon:after {
        transform: translateY(-12px) rotate(-135deg);
      }
      
      .nav-icon div {
        transform: scale(0);
      }
    }
  }
}