.container, .grid-x {
  max-width: 1500px;
  margin: auto;
}

p, ul{
  line-height: 2.2em;
  font-size: 1.1rem;
}

.page-contain {
  padding-top: $upper-nav-height+$lower-nav-height + 60px;
  min-height: 60vh;
}

.notfound {
  h1{
    font-size: 8em;
  }

  p {
    font-size: 1.2em;
  }
}

//Semantic Styles

.float-left {
  float: left;
}

.float-right {
  float: right;
}

@mixin down-x ($x) {
  padding-top: $x;
}
@mixin spread-x ($x) {
  padding-right: $x;
  padding-left: $x;
}
@mixin bot-x ($x) {
  padding-bottom: $x;
}
@mixin pad-xy ($x, $y) {
  padding: $y $x;
}

.no-pad {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.pad-half {
  padding: 0.5em;
}

.pad-1 {
  padding: 1em;
}

.pad-2 {
  padding: 2em;
}

.pad-3 {
  padding: 3em;
}
/* Bottom*/
.bot-half {
  padding-bottom: 0.5em;
}

.bot-1 {
  padding-bottom: 1em;
}

.bot-2 {
  padding-bottom: 2em;
}

.bot-3 {
  padding-bottom: 3em;
}
/* Spread*/
.spread-1 {
  padding-right: 1em;
  padding-left: 1em;
}

.spread-2 {
  padding-right: 2em;
  padding-left: 2em;
}

.spread-3 {
  padding-right: 3em;
  padding-left: 3em;
}
/* Down*/
.down-1 {
  padding-top: 1em;
}

.down-2 {
  padding-top: 2em;
}

.down-3 {
  padding-top: 3em;
}

.down-half {
  padding-top: 0.5em;
}

//Angled Edges
@mixin angle($pseudo, $flip: false, $angle: 1.5deg) {
  // Possible values for $pseudo are: before, after, both
  @if $pseudo == 'before' or $pseudo == 'after' or $pseudo == 'both' {
    position: relative;
    z-index: 1;
    $selector: if($pseudo == 'both', '&:before,&:after', '&:#{$pseudo}');

    #{$selector} {
      background: inherit;
      content: '';
      display: block;
      height: 50%;
      left: 0;
      position: absolute;
      right: 0;
      z-index: -1;
      -webkit-backface-visibility: hidden; // for Chrome Windows
    }

    @if $pseudo == 'before' {
      #{$selector} {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }
    }

    @if $pseudo == 'after' {
      #{$selector} {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 100%;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }

    @if $pseudo == 'both' {
      &:before {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }

      &:after {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }
  }
}
