.mf_links {
  text-align: center;
  .mf_link {
    text-align: center;
    display: inline-block;
    padding: 10px 20px;
    margin: 0 10px;
    transition: .5s all ease-out;
    border: 2px solid $primary-color;

    h2 {
      font-family: $body-font-family;
      font-weight: bolder;
      font-size: 1.1em;
      display: inline;
      color: $primary-color;
      transition: .5s all ease-out;
    }

    &:hover {
      background: $primary-color;
      h2 {
        color: #FFF;
      }
    }
  }
}

.kit_link {
  background: #F8f8f8;
  display: inline-block;
  padding: 8px 22px;
  transition: .5s all ease-in;
  margin: 5px;
  border: 1px solid #e8e8e8;
  font-weight: bolder;

  &:hover {
    background: $primary-color;
    color: #FFF;
  }
}

.kit-full {
  figure {
    figcaption {
      text-align: center;
      font-style: italic;
    }
    margin-bottom: 30px;
  }

  .button {
    font-weight: bolder;
    font-size: 1.2em;
  }

  .manufacturer {
    display: block;
    font-size: 1.4em;
    margin-bottom: 20px;
    font-style: italic;
  }

  h4 {
    margin-bottom: 10px;
  }

  h5 {
    font-family: $body-font-family;
    font-weight: bolder;
  }
}