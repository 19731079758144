#intro {
  padding-top: $upper-nav-height+$lower-nav-height;
  height: 80vh;
  min-height: 650px;

  picture {
    width: 100%;
    height: 100%;
    display: flex;
    img {
      object-fit: cover; 
      object-position: bottom;
      height: auto;
      width:100%;
    }   
  }
}

#whyus {
  padding: 10px 0 100px;

}

#resources {
  background-color: $navy;
  font-family: $header-font-family;
  padding: 250px 0 300px;
  position: relative;

  &:before {
    content: " ";
    background-image: url(/assets/img/resourcetop.png);
    background-size: cover;
    height: 170px;
    width: 100%;
    background-color: #FFF;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:after {
    content: " ";
    background-image: url(/assets/img/resourcebottom.jpg);
    background-size: cover;
    background-position: left;
    height: 170px;
    width: 100%;
    background-color: $primary-color;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  h2 {
    color: #FFF;
  }

  a {
    font-size: 1.6em;
    color: #FFF;
    display: block;
    position: relative;
    padding: 15px 0;

     &:before {
       content: " ";
       height: 2px;
       width: 20%;
       background: $primary-color;
       position: absolute;
       top: 0;
       left: 0;
     }
  }
}