.resource-link {
  font-size: 1.4em;
  display: block;
  font-weight: bolder;
  .date {
    color: #222;
    font-size: .8em;
    font-weight: normal;
  }
  padding-bottom: 20px;
}