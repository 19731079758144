@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,700|Playfair+Display:500&display=swap');

$foundation-palette: (
  primary: #017cbc,
  secondary: #94c2e1,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
) !default;

$bg-color: #F7F6EE;
$navy: #010b2e;

$footer-height: 90px;
$nav-height: 110px;

$header-font-family: 'Playfair Display', serif;
$body-font-family: 'Fira Sans', sans-serif;

$upper-nav-height: 35px;
$lower-nav-height: 110px;